import {cleanUrlPathPart as c} from '../../commons/utils/urlUtils'

export const digitalPathParams = {
  contentNodeId: 'contentNodeId',
  contentNodeName: 'contentNodeName',
}

export const digitalQueryParams = {
  redirect: 'r',
}

const BASE_PATH = '/'
const BASE_PATH_CONTENT = '/c'

export const digitalPathOverview = () => {
  return BASE_PATH
}

export const digitalContentPageId = (contentNodeId: string) => {
  return `${BASE_PATH_CONTENT}/${contentNodeId}`
}

export const digitalContentPage = (contentNodeId: string, name: string) => {
  return `${BASE_PATH_CONTENT}/${contentNodeId}/${c(name)}`
}

export const digitalLoginPage = (redirectUri?: string | null) => {
  if (!redirectUri) {
    return `${BASE_PATH}login`
  }
  return `${BASE_PATH}login?${digitalQueryParams.redirect}=${redirectUri}`
}

export const digitalAkLoginPage = (redirectUri?: string | null) => {
  if (!redirectUri) {
    return `${BASE_PATH}login-ak`
  }
  return `${BASE_PATH}login-ak?${digitalQueryParams.redirect}=${redirectUri}`
}

export const digitalKvsLoginPage = (redirectUri?: string | null) => {
  if (!redirectUri) {
    return `${BASE_PATH}login-kvs`
  }
  return `${BASE_PATH}login-kvs?${digitalQueryParams.redirect}=${redirectUri}`
}
